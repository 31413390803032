"use client";

import clsx from "clsx";
import type { ForwardedRef } from "react";
import { forwardRef, useEffect, useRef, type SVGProps } from "react";
import type { ParsedPath, Segment } from "svg-path-parse";
import { pathParse, serializePath } from "svg-path-parse";

type BreathingPathProps = {
  /** Relative offset in radians */
  offset: number;
  startIndex?: number;
  /** @default {2} */
  amplitude?: number;
  speed?: number;
  d: string;
  ignoredPointIndexes?: number[];
};

const updateInterval = 100;

const BreathingPath = (
  props: SVGProps<SVGPathElement> & BreathingPathProps,
) => {
  const ref = useRef<SVGPathElement>(null);
  const {
    d: path,
    offset,
    startIndex = 0,
    amplitude = 2,
    speed = 1,
    ignoredPointIndexes,
    ...rest
  } = props;
  const parsedPath = pathParse(path);
  const getBreathingPath = (path: ParsedPath, offset: number) => {
    const newSegments = path
      .getSegments()
      .segments.map((segment, index): Segment => {
        if (ignoredPointIndexes?.includes(index)) {
          return segment;
        }
        if (segment[0].toLowerCase() === "c") {
          return [
            segment[0],
            ...(segment.slice(1) as number[]).map(
              (s) =>
                s +
                amplitude *
                  Math.sin(
                    (new Date().getTime() / 1000 +
                      (index + startIndex) * 0.5 +
                      offset) *
                      speed,
                  ),
            ),
          ];
        }
        return segment;
      });

    return serializePath({
      err: "",
      segments: newSegments,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      ref.current?.setAttribute("d", getBreathingPath(parsedPath, offset));
    }, updateInterval);
    return () => clearInterval(interval);
  });

  return (
    <path
      {...rest}
      ref={ref}
      className={clsx("transition-all ease-linear", rest.className)}
      style={{
        transitionDuration: `${updateInterval}ms`,
      }}
      d={path}
    />
  );
};

export const LargeWaves = forwardRef(function LargeWaves(
  props: SVGProps<SVGSVGElement>,
  ref: ForwardedRef<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#6AC"
      ref={ref}
      viewBox="0 0 1849 943"
      {...props}
      className={clsx("inline overflow-visible", props.className)}
    >
      <BreathingPath
        offset={0}
        d="M1641 965H0c21-223 25-266 139-358 114-93 152-73 220-188s250-124 390-72c140 53 289 28 382-37 93-66 130-72 281-85s170-119 229-95c59 25 78 79 40 163-38 85 78 162 19 344s-59 328-59 328Z"
      />
      <BreathingPath
        offset={1}
        fillOpacity={0.5}
        d="M1754 949 104 863c21-223-44-281 70-373 113-93 248-18 320-95 71-77 208-152 348-99 140 52 319 34 412-32 92-65 127-106 207-152 80-47 131-73 199-41 68 33 103 129 74 197-28 68-75 261-67 357 7 96 87 324 87 324Z"
      />
      <BreathingPath
        offset={2}
        fillOpacity={0.5}
        d="M1849 989 145 779c21-223-44-281 70-374 113-92 248-17 320-94 71-78 208-152 348-100s319 34 412-32c92-65 139-120 229-161 140-63 245 45 277 177 32 131-172 316-79 501 48 97 127 293 127 293Z"
        opacity={0.5}
      />
    </svg>
  );
});

export const SmallWaves = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#6AC"
      viewBox="0 0 390 642"
      {...props}
    >
      <BreathingPath
        offset={0}
        amplitude={6}
        d="M259,197.5C237.762,231.127,206,230.074,170.5,220C102.809,200.79,69.5,286,0,271.5S-71,674,-71,674L390,705C405.571,452.977,421,56.025999999999954,390,65C359,73.974,364.5,103.5,342,135.5S283,159.5,259,197.5Z"
      />
      <BreathingPath
        offset={1}
        fillOpacity={0.5}
        amplitude={4}
        d="M247,153.5C206.59,184.988,181.5,238.5,156,232C96.106,216.733,55.5,194,0,241.5S-43.5,659,-43.5,659L390,675V35S374.5,73.5,342,105.5S285.5,123.5,247,153.5Z"
      />
      <BreathingPath
        offset={2}
        fillOpacity={0.5}
        d="M294,180.5C261.203,219.856,182.5,206.5,156,197C104.119,178.401,21,169.5,0,206.5S-99.5,663,-99.5,663L390,640V0S368,33,342,70.5S319,150.5,294,180.5Z"
        opacity={0.5}
      />
    </svg>
  );
};

export const FooterWaves = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#6AC"
      viewBox="0 0 1039 220"
      {...props}
    >
      <BreathingPath
        offset={0}
        speed={3}
        amplitude={2}
        d="M51.5 256.5C51.5 187 84.6212 101.088 178 113.432C237 121.232 264 70.0787 291 44.4322C318 18.7857 355.398 9.35191 424 44.4322C474 70 547.5 92 635 53.3022C740.352 6.70915 911.5 155.5 986.5 273L51.5 256.5Z"
      />
      <BreathingPath
        offset={1}
        d="M298 55C325 29.3535 478.93 11.4701 546.5 48.5C635 97 666 169 937 237.5L93 286.5C97 259.833 68 109.45 176.5 93.5C240.5 84.0915 271 80.6465 298 55Z"
        fill-opacity="0.5"
      />
      <BreathingPath
        offset={2}
        opacity="0.5"
        d="M342.5 30.5C408 -1.36471 463.5 -16.5 604.5 30.5C745.5 77.5 855.5 146 1022 303L75 292C79 265.333 66.5205 110.465 152.5 72C209.5 46.5 277 62.3648 342.5 30.5Z"
        fill-opacity="0.5"
      />
    </svg>
  );
};
