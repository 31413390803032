"use client";

import { useEffect, useRef } from "react";
import { LargeWaves, SmallWaves } from "../../components/Waves";

export default function ClientPage({ age }: { age: number }) {
  const contentRef = useRef<HTMLDivElement>(null);
  const waveContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const content = contentRef.current;
    const waveContainer = waveContainerRef.current;

    function isVisible(e: Element | HTMLElement) {
      return !!(
        ("offsetHeight" in e ? e.offsetHeight : 0) || e.getClientRects().length
      );
    }

    if (!content || !waveContainer) return;

    const handleResize = () => {
      const visibleChildren = Array.from(waveContainer?.children || []).filter(
        (child) => isVisible(child) && child.tagName === "svg",
      );

      const offset = visibleChildren.reduce((acc, child) => {
        const bottom = child.getBoundingClientRect().bottom;
        return bottom > acc ? bottom : acc;
      }, 0);
      const bottomOfContent = content.getBoundingClientRect().bottom;
      waveContainer.style.setProperty("--top", `${bottomOfContent}px`);
      waveContainer.style.setProperty(
        "--offset",
        `${window.innerHeight - offset}px`,
      );
    };

    handleResize();
    window.addEventListener("resize", handleResize, {
      passive: true,
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="mx-auto flex h-full w-full flex-col">
      <div ref={contentRef} className="mx-auto w-full max-w-page p-8">
        <h1 className="mb-5 text-5xl font-bold sm:text-7xl">Alve</h1>
        <p className="text-2xl leading-normal text-zinc-400 sm:text-3xl sm:leading-normal">
          <span className="text-white">Full-stack developer,</span>
          <br />
          {age} years old,
          <br />
          Sweden
        </p>
      </div>
      <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden">
        <div ref={waveContainerRef} className="relative h-full w-full">
          <SmallWaves className="absolute top-[calc(var(--top)_-_33vw)] md:hidden" />
          <LargeWaves className="absolute left-[3vw] top-[calc(var(--top)_-_15vw)] hidden w-[120vw] md:block 6xl:hidden" />
          <div className="absolute bottom-[min(0rem,_calc(100vh_-_56rem))] left-0 right-0 top-0 hidden items-end justify-center 6xl:flex ">
            <LargeWaves className="max-w-page" />
          </div>
          <div className="absolute bottom-0 h-[var(--offset)] w-full bg-primary 6xl:hidden" />
        </div>
      </div>
    </div>
  );
}
